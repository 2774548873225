import MusicPlayerComponent from '@wix/thunderbolt-elements/src/components/MusicPlayer/viewer/MusicPlayer';
import MusicPlayerController from '@wix/thunderbolt-elements/src/components/MusicPlayer/viewer/MusicPlayer.controller';


const MusicPlayer = {
  component: MusicPlayerComponent,
  controller: MusicPlayerController
};


export const components = {
  ['MusicPlayer']: MusicPlayer
};

